<template>
  <div class="advcost-board comparison-box">
    <headerNav :title="$route.params.marketName + '市场数据对比'" :leftIcon="true" @leftClick="back" />
    <van-tabs v-model="active" class="my-tabs">
      <van-tab title="图形对比">
        <div class="content">
          <div class="content-bg">
            <div class="top-title">
              <img :src="require('@/assets/a20.svg')">
              原始任务
              <van-switch v-model="checked" active-color="#FF8C21" inactive-color="#dcdee0" size="25px" />
            </div>
          </div>
          <ul>
            <li v-for="(item, index) in  infoList " :key="item.name">
              <div class="item-title">
                <img :src="item.icon">
                {{ item.name }}
              </div>
              <div class="item-content" v-if="checked">
                <p>
                  原始完成率
                  <span class="number">{{ item.taskScale }}%</span>
                </p>
                <p>
                  <span class="old">原始 </span>&nbsp;
                  <span class="number" v-if="index < 2">{{ item.taskNum.toFixed(2) }}</span>
                  <span class="number" v-else>{{ item.taskNum }}</span>&nbsp;
                  <span> {{ item.unit }}</span>
                </p>
              </div>
              <div class="item-content">
                <p>
                  最终完成率
                  <span class="number">{{ item.scale }}%</span>
                </p>
                <p>
                  <span class="new">最终 </span>&nbsp;
                  <span class="number" v-if="index < 2">{{ item.lastTaskNum.toFixed(2) }}</span>
                  <span class="number" v-else>{{ item.lastTaskNum }}</span>&nbsp;
                  <span> {{ item.unit }}</span>
                </p>
              </div>
              <div class="progress-box" v-if="checked">
                <span v-if="item.name == '转单产值'" :style="getStyle(item)">
                  <el-progress class="my-progress" :show-text="false" :stroke-width="6" :percentage="100"></el-progress>
                  <img :src="require('@/assets/a30.svg')">
                </span>
                <span v-else-if="item.name == '广告费用'" :style="getStyle(item)">
                  <el-progress class="my-progress ad" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a33.svg')">
                </span>
                <span v-else :style="getStyle(item)">
                  <el-progress class="my-progress normal" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a34.svg')">
                </span>
                <span v-if="item.type == 0 && item.realityNum < item.lastTaskNum" style="z-index: 1;">
                  <el-progress class="my-progress-1" color="#FDE9CD" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a35.svg')">
                </span>
                <template v-if="item.type > 0 && item.realityNum < item.lastTaskNum">
                  <span style="z-index: 1;">
                    <el-progress class="my-progress-1" color="#FDE9CD" :show-text="false" :stroke-width="6"
                      :percentage="100"></el-progress>
                    <img :src="require('@/assets/a31.svg')">
                  </span>
                  <span :style="getStyle2(item.taskNum, item.lastTaskNum)" style="z-index: 2;"
                    v-if="item.realityNum < item.taskNum">
                    <el-progress class="my-progress-2" color="#ECF7FF" :show-text="false" :stroke-width="6"
                      :percentage="100"></el-progress>
                    <img :src="require('@/assets/a32.svg')">
                  </span>
                </template>
                <template v-if="item.type < 0 && item.realityNum < item.taskNum">
                  <span style="z-index: 1;">
                    <el-progress class="my-progress-2" color="#ECF7FF" :show-text="false" :stroke-width="6"
                      :percentage="100"></el-progress>
                    <img :src="require('@/assets/a32.svg')">
                  </span>
                  <span :style="getStyle2(item.lastTaskNum, item.taskNum)" style="z-index: 2;"
                    v-if="item.realityNum < item.lastTaskNum">
                    <el-progress class="my-progress-1" color="#FDE9CD" :show-text="false" :stroke-width="6"
                      :percentage="100"></el-progress>
                    <img :src="require('@/assets/a31.svg')">
                  </span>
                </template>
              </div>
              <div class="progress-box" v-else>
                <span v-if="item.name == '转单产值'" :style="getStyle(item, item.lastTaskNum)">
                  <el-progress class="my-progress" :show-text="false" :stroke-width="6" :percentage="100"></el-progress>
                  <img :src="require('@/assets/a30.svg')">
                </span>
                <span v-else-if="item.name == '广告费用'" :style="getStyle(item)">
                  <el-progress class="my-progress ad" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a33.svg')">
                </span>
                <span v-else :style="getStyle(item)">
                  <el-progress class="my-progress normal" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a34.svg')">
                </span>
                <span v-if="item.type == 0 && item.realityNum < item.lastTaskNum" style="z-index: 1;">
                  <el-progress class="my-progress-1" color="#FDE9CD" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a35.svg')">
                </span>
                <span v-if="item.realityNum <= item.lastTaskNum" style="z-index: 1;">
                  <el-progress class="my-progress-1" color="#FDE9CD" :show-text="false" :stroke-width="6"
                    :percentage="100"></el-progress>
                  <img :src="require('@/assets/a31.svg')">
                </span>
              </div>
              <div class="item-bottom">
                <p class="bottom-p1" v-if="checked">
                  <img v-if="item.type > 0" :src="require('@/assets/a28.svg')">
                  <img v-else-if="item.type < 0" :src="require('@/assets/a29.svg')">
                  较原始任务
                  <span :class="item.type > 0 ? 'number up' : item.type < 0 ? 'number down' : 'number'">{{
                    item.compareNum > 0 ? '+' + item.compareNum : item.compareNum
                  }}</span>
                  {{ item.unit }}
                </p>
                <p class="bottom-p2">实际 <span class="number">{{ item.realityNum }}</span> {{ item.unit }}</p>
              </div>
            </li>
          </ul>
        </div>
      </van-tab>
      <van-tab title="表格对比">
        <div class="month">
          {{ month }}<span>月</span>
        </div>
        <el-table :data="designListData" header-cell-class-name="table-head-cell">
          <el-table-column prop="name" label="数据项" width="105">
            <template slot-scope="scope">
              <span class="text-style">{{ scope.row.name }}{{ (scope.row.unit && scope.row.name.indexOf('率') == -1 &&
                scope.row.name.indexOf('比例') == -1) ? '(' +
                scope.row.unit + ')' : '' }}</span>
              <van-popover v-model="showHint1" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
                v-if="scope.row.name == '广告费用比例'">
                <div class="hint-text">广告费用占转单产值的比例</div>
                <template #reference>
                  <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
                </template>
              </van-popover>
              <van-popover v-model="showHint2" trigger="click" theme="dark" placement="top" :offset="[0, 10]"
                v-if="scope.row.name == '有效量'">
                <div class="hint-text">线索有效量</div>
                <template #reference>
                  <img :src="require('@/assets/a15.svg')" class="hint-icon" alt="">
                </template>
              </van-popover>
            </template>
          </el-table-column>
          <el-table-column prop="taskNum" label="原始任务" width="85" align="right">
            <template slot-scope="scope">
              <span class="number1-style" v-if="[0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index)">{{
                scope.row.taskNum.toFixed(2)
              }}{{ scope.row.unit == '%' ? '%' : '' }}</span>
              <span class="number1-style" v-else>{{ scope.row.taskNum }}{{ scope.row.unit == '%' ? '%' : '' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="lastTaskNum" label="最终任务" align="right">
            <template slot-scope="scope">
              <span class="number2-style" v-if="[0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index)">{{
                scope.row.lastTaskNum.toFixed(2) }}{{ scope.row.unit == '%' ? '%' : ''
  }}</span>
              <span v-else class="number2-style">{{ scope.row.lastTaskNum }}{{ scope.row.unit == '%' ? '%' : ''
              }}</span>
              <img v-if="scope.row.type > 0" :src="require('@/assets/a16.svg')" alt="" class="number-arrow">
              <img v-else-if="scope.row.type < 0" :src="require('@/assets/a17.svg')" alt="" class="number-arrow">
              <img v-else :src="require('@/assets/a18.svg')" alt="" class="number-arrow">
            </template>
          </el-table-column>
          <el-table-column prop="realityNum" label="实际完成" align="right">
            <template slot-scope="scope">
              <span v-if="[0, 1, 2, 3, 5, 7, 9, 11].includes(scope.$index)"
                :class="scope.row.realType > 0 ? 'number3-style success' : 'number3-style  error'">{{
                  scope.row.realityNum.toFixed(2)
                }}{{ scope.row.unit == '%' ? '%' : '' }}</span>
              <span v-else :class="scope.row.realType >= 0 ? 'number3-style success' : 'number3-style  error'">{{
                scope.row.realityNum
              }}{{ scope.row.unit == '%' ? '%' : '' }}</span>
              <img v-if="scope.row.realType >= 0" :src="require('@/assets/a37.svg')" alt="" class="sc-arrow">
              <img v-else :src="require('@/assets/a36.svg')" alt="" class="sc-arrow">
            </template>
          </el-table-column>
          <div slot="empty" style="text-align: left;">
            <div class="table-empty">
              <img :src="require('@/assets/a-empty.png')">
              <span>暂无数据</span>
            </div>
          </div>
        </el-table>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import api from '../../../api/api'

export default {
  data () {
    return {
      active: 0,
      designListData: [],
      infoList: [],
      showHint1: false,
      showHint2: false,
      checked: true,
      month: this.$route.params.taskPeriod.split('-')[1]
    }
  },
  methods: {
    //返回
    back () {
      this.$router.push({ name: 'TaskAdvcost' })
    },
    async getComparisonData () {
      let obj = {
        taskPeriod: this.$route.params.taskPeriod,
        marketCode: this.$route.params.marketCode
      }
      let res = await api.getComparisonData(obj)
      this.designListData = res.data.compareItemList.map(e => {
        if (e.name.indexOf('比例') == -1 && e.name.indexOf('均单产值') == -1 && e.name.indexOf('率') == -1) {
          this.infoList.push(e)
        }
        return e
      })
      this.infoList = this.infoList.map((e, i) => {
        e.icon = require(`../../../assets/a${21 + i}.svg`)
        return e
      })
    },
    getStyle (item, maxNumber) {
      if (maxNumber) {
        let l = (item.realityNum * 100 / maxNumber).toFixed(2) < 2 ? 2 : (item.realityNum * 100 / maxNumber).toFixed(2)
        return `width:${l > 100 ? 100 : l}%;z-index:3`
      }
      if (item.realityNum > item.lastTaskNum && item.type == 0) return `width:100%;z-index:3`
      let max = item.type < 0 ? item.taskNum : item.lastTaskNum
      let l = (item.realityNum * 100 / max).toFixed(2) < 2 ? 2 : (item.realityNum * 100 / max).toFixed(2)
      return `width:${l > 100 ? 100 : l}%;z-index:3`
    },
    getStyle2 (n1, n2) {
      let l = (n1 * 100 / n2).toFixed(2) < 2 ? 2 : (n1 * 100 / n2).toFixed(2)
      return `width:${l > 100 ? 100 : l}%`

    }
  },
  mounted () { },
  created () {
    this.getComparisonData()
  }
}
</script>

<style scoped lang="scss">
.advcost-board {
  background: #F5F5F5;
  min-height: 100vh;

  .my-tabs {
    margin-top: 1px;
  }

}

.month {
  width: 51px;
  height: 40px;
  background: #3975C6;
  border-radius: 8px 0 0 8px;
  position: absolute;
  right: 0;
  top: 6px;
  font-size: 23px;
  font-family: Bahnschrift, Bahnschrift;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 45px;

  &>span {
    font-size: 14px;
  }
}

.content {
  position: relative;

  .content-bg {
    margin-top: 8px;
    border-radius: 17px 17px 0 0;
    width: 100%;
    height: 400px;
    background: linear-gradient(180deg, #3975C6 0%, rgba(57, 117, 198, 0) 100%);

    .top-title {
      display: flex;
      align-items: center;
      height: 43px;
      padding: 0 12px 0 15px;
      font-size: 19px;
      font-family: PingFang TC, PingFang TC;
      font-weight: 500;
      color: #FFFFFF;

      img {
        width: 21px;
        height: 21px;
        margin-right: 10px;
      }

      .van-switch {
        margin-left: auto;
      }
    }
  }

  &>ul {
    position: absolute;
    top: 43px;
    height: calc(100vh - 160px);
    overflow: auto;
    width: 100%;
    padding: 0 8px;
    text-align: left;
    box-sizing: border-box;

    li {
      background: #FFFFFF;
      border-radius: 8px;
      margin-bottom: 8px;
      padding: 17px 8px;
      box-sizing: border-box;

      .item-title {
        font-size: 19px;
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        color: #333333;
        display: flex;
        align-items: center;

        img {
          width: 25px;
          height: 25px;
          margin-right: 8px;
        }
      }

      .item-content {
        display: flex;
        margin-top: 8px;

        &>p {
          flex: 1;
          margin: 0;
          font-size: 15px;
          font-family: PingFang TC, PingFang TC;
          font-weight: 400;
          color: #999999;
          // align-items: center;


          &:last-child {
            text-align: right;
            display: flex;
            justify-content: flex-end;
          }

          .old {
            color: #3975C6;
            position: relative;

            &::before {
              position: absolute;
              content: ' ';
              width: 8px;
              height: 8px;
              background: #3975C6;
              left: -15px;
              top: calc(50% - 7px);
              -webkit-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }
          }

          .new {
            color: #F1AD49;
            position: relative;

            &::before {
              position: absolute;
              border-radius: 4px;
              content: ' ';
              width: 8px;
              height: 8px;
              background: #F1AD49;
              left: -15px;
              transform: translateY(85%)
            }
          }
        }
      }

      .number {
        font-size: 19px;
        font-family: Bahnschrift, Bahnschrift;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
      }

      .up {
        color: #F63232;
      }

      .down {
        color: #25CD68;
      }

      .progress-box {
        display: flex;
        width: 100%;
        align-items: center;
        position: relative;
        height: 30px;

        &>span {
          position: absolute;
          width: 100%;

          img {
            position: absolute;
            right: -5px;
            top: calc(50% - 10px);
            height: 20px;
          }
        }

        .my-progress {

          // margin-top: 6px;
          // margin-bottom: 5px;
          ::v-deep {
            .el-progress-bar__inner {
              background: linear-gradient(-45deg, #F9996F 25%, #F66D32 0,
                  #F66D32 50%, #F9996F 0,
                  #F9996F 75%, #F66D32 0);
              background-size: 50px 50px;
            }
          }
        }

        .ad {
          ::v-deep {
            .el-progress-bar__inner {
              background: linear-gradient(-45deg, #FF9089 25%, #FF6157 0,
                  #FF6157 50%, #FF9089 0,
                  #FF9089 75%, #FF6157 0);
              background-size: 50px 50px;
            }
          }
        }

        .normal {
          ::v-deep {
            .el-progress-bar__inner {
              background: linear-gradient(-45deg, #6FB7F9 25%, #3298F6 0,
                  #3298F6 50%, #6FB7F9 0,
                  #6FB7F9 75%, #3298F6 0);
              background-size: 50px 50px;
            }
          }
        }

        .my-progress-1 {
          ::v-deep {
            .el-progress-bar__outer {
              border: 1px solid #FFC14A;
            }
          }
        }

        .my-progress-2 {
          ::v-deep {
            .el-progress-bar__outer {
              border: 1px solid #ADD8FF;
            }
          }
        }
      }

      .item-bottom {
        display: flex;
        justify-content: space-between;
        // margin-top: 18px;

        &>p {
          margin: 0;
          display: flex;
          align-items: center;
          padding: 0 5px;

          img {
            height: 16px;
            margin-right: 8px;
          }

        }

        .bottom-p1 {
          background: #F5F5F5;
          font-size: 15px;
          font-family: PingFang TC, PingFang TC;
          font-weight: 400;
          color: #999999;
        }

        .bottom-p2 {
          font-size: 15px;
          font-family: PingFang TC, PingFang TC;
          font-weight: 400;
          color: #999999;
          margin-left: auto;
        }
      }
    }
  }
}

.table-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    margin-bottom: 10px;
    margin-top: 100px;
  }

  span {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #5E5E5E;
  }
}

.comparison-box {
  .text-style {
    font-size: 12px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    color: #999999;
  }

  .number1-style {
    font-size: 15px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: 300;
    color: #1D2129;
  }

  .number2-style {
    font-size: 15px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: 300;
    color: #333333;
  }

  .number3-style {
    font-size: 15px;
    font-family: Bahnschrift, Bahnschrift;
    font-weight: 400;
  }

  .number-arrow {
    width: 13px;
    margin-bottom: -1px;
  }

  .sc-arrow {
    width: 12px;
    margin-left: 4px;
  }

  .error {
    color: #FB3640;
  }

  .success {
    color: #17CA5E;
  }

  ::v-deep {
    .cell {
      padding: 0 8px;
      padding-right: 4px;

      &:has(.number3-style) {
        padding-left: 0;
      }

      &:has(.number2-style) {
        padding-left: 0;
      }

      &:has(.number1-style) {
        padding-left: 0;
      }

      &:has(.text-style) {
        padding-right: 0;
      }
    }
  }
}

.hint-icon {
  width: 13px;
  margin-left: 2px;
  margin-bottom: -2px;
}

.hint-popover {
  height: 16px;
  display: flex;
}




.hint-text {
  line-height: 25px;
  padding: 0 10px;
}

::v-deep {
  .van-tab {
    flex: none;
    width: 100px;
    font-size: 18px;
    font-family: PingFang SC, PingFang SC;
    font-weight: 500;
    color: #333333;

  }

  .van-tab--active {
    color: #3DA2FF;
    font-weight: bold;
  }

  .van-tabs__line {
    background: #3DA2FF;
    border-radius: 2px 2px 0 0;
  }

  .van-tabs__wrap {
    height: 56px;
  }

  .el-table__empty-text {
    width: 100%;
  }

  .el-table__cell {
    text-overflow: unset !important;
  }
}
</style>
<style lang="scss">
.table-head-cell {
  background: #F5F5F5 !important;
  font-size: 13px;
  font-family: PingFang SC, PingFang SC;
  font-weight: bold;
  color: #1D2129;
  &:first-child .cell{
    padding-left: 4px!important;
    padding-right: 0;
  }
  .cell {
    box-sizing: border-box;
  }
}

.comparison-box {
  .cell {
    text-overflow: unset !important;
  }
  tbody>tr td:first-child .cell{
    padding-left: 4px!important;
  }
}
</style>